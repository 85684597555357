@import 'src/assets/mixins';

/* stylelint-disable-next-line selector-max-type */
html,
body {
  height: 100%;
  overflow: hidden;
}

/* stylelint-disable-next-line selector-max-id */
#root {
  height: 100%;

  [data-iframe] & {
    height: auto;
  }
}

.row {
  @include iframe-override {
    @media (min-width: $screen-lg-min) {
      .col-lg-6 {
        width: 50%;
      }
    }
  }
}
