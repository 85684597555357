@import '~@setapp/ui-kit/styles/variables';
@import 'src/assets/mixins';

.gift-cards-list {
  margin: 0 -15px;
  padding: 0;
  list-style: none;

  @media (min-width: $screen-md-min) {
    display: flex;
    flex-flow: row wrap;
  }

  &__item {
    padding: 15px;

    @media (min-width: $screen-md-min) {
      flex: 0 0 50%;
    }

    @include iframe-override {
      @media (min-width: $screen-lg-min) {
        flex-basis: calc(100% / 3);
      }
    }
  }
}
